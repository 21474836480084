import React from "react";

class MessengerChat extends React.Component {
  componentDidMount() {
    // @ts-ignore
    window.fbAsyncInit = function() {
      // @ts-ignore
      FB.init({
        xfbml: true,
        version: 'v10.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  render() {
    return (
      <>
        <div id="fb-root" />
        <div
          className="fb-customerchat"
          // @ts-ignore
          attribution="biz_inbox"
          page_id="174806682892222" />
      </>
    );
  }
}

export default MessengerChat;
